@import url('./animated.css');

@font-face {
    font-family: "Poppins";   /*Can be any text*/
    src: local("Poppins"),
      url("../fonts/Poppins-Regular.ttf") format("truetype");
      font-weight: 400;
}
@font-face {
    font-family: "Poppins";   /*Can be any text*/
    src: local("Poppins"),
      url("../fonts/Poppins-Italic.ttf") format("truetype");
      font-weight: 400;
      font-style: italic;
}
@font-face {
    font-family: "Poppins";   /*Can be any text*/
    src: local("Poppins"),
      url("../fonts/Poppins-Medium.ttf") format("truetype");
      font-weight: 500;
}
@font-face {
    font-family: "Poppins";   /*Can be any text*/
    src: local("Poppins"),
      url("../fonts/Poppins-MediumItalic.ttf") format("truetype");
      font-weight: 500;
      font-style: italic;
}
@font-face {
    font-family: "Poppins";   /*Can be any text*/
    src: local("Poppins"),
      url("../fonts/Poppins-Bold.ttf") format("truetype");
      font-weight: 600;
}
@font-face {
    font-family: "Poppins";   /*Can be any text*/
    src: local("Poppins"),
      url("../fonts/Poppins-BoldItalic.ttf") format("truetype");
      font-weight: 400;
      font-style: italic;
}
@font-face {
    font-family: "Poppins";   /*Can be any text*/
    src: local("Poppins"),
      url("../fonts/Poppins-Thin.ttf") format("truetype");
      font-weight: 300;
}
@font-face {
    font-family: "Poppins";   /*Can be any text*/
    src: local("Poppins"),
      url("../fonts/Poppins-ThinItalic.ttf") format("truetype");
      font-weight: 300;
      font-style: italic;
}


html{
    --dark-blue: rgb(66, 87, 138);
    --light-blue: rgb(25, 61, 150);
    --light-blue-hover: rgba(15, 47, 223, 0.63); 
    --dark-blue-hover: rgb(22, 52, 128);
}
body{
    margin: 0px;
}

*{
    font-family: "Poppins", sans-serif;
}
a{
    text-decoration: none;
}
/* BACKGROUNDS */
.topo{
    background-image: url("../img/fundo-2.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
    padding-bottom: 150px;
}

.wrapper{
    max-width: 1264px;
    margin: 0 auto;
    padding-left: 10px;
    padding-right: 10px;
}

h1, h2, h3, h4, h5, h6{
    color: var(--light-blue) !important;
}

.center{
    text-align: center;
}

.logo{
    width: 150px;
    margin: 0 auto;
    display: inline-block;
}

h1.title{
    font-size: 96px;
    text-align: center;
    line-height: 1;
    color: var(--dark-blue);
    margin-top: 50px;
}

h1.title span{
    color: var(--light-blue);
}

h1.title.small{
    font-size: 58px;
}

h3.destaque{
    font-size: 28px;
    font-weight: 400;
    text-align: center;
    display: block;
    padding-top: 0px;
    margin-top: 0px;
}

.video{
    background-color: white;
    padding-bottom: 50px;
}

.video-wrapper{
    display: grid;
    max-width: 1140px;
    margin: 0 auto;
}

.video-wrapper .white-ghost{
    background-color: rgba(255, 255, 255, 0.3);
    padding: 50px 50px 20px 50px;
    margin-top: -140px;
    display: block;
    border-radius: 10px;
    text-align: center;
    /* shadow */
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.1);
}

.video-wrapper .white-ghost iframe{
    width: 100%;
    height: 585px;
}


.ant-btn:hover,
.btn:hover{
    background-color: var(--dark-blue-hover);
    transition: .3s;
}

.ant-btn-default,
.ant-btn,
.btn{
    text-transform: uppercase;
    padding: 15px 20px;
    background-color: var(--light-blue);;
    transition: .3s;
    border-radius: 10px;
    color: white;
    box-shadow: 0px 1px 22px 0px rvar(--light-blue-hover) ;
}

.ant-btn-primary:hover,
.ant-btn-default:hover{
    background-color: var(--dark-blue-hover) !important;
    color: white !important;
}

.ant-btn-primary{
    background-color: transparent;
    color: var(--dark-blue-hover);
}


.video-wrapper .white-ghost .btn{
    display: inline-block;
    margin-top: 35px;
}


.login .ant-form-item-control div:nth-child(2){
    height: 0px;
}
.login .ant-form-item-control div:nth-child(2), 
.login .ant-form-item-control div:nth-child(2) .ant-form-item-explain-error{
    background-color: white !important;
    top: 30px;
    left: 10px;
}
.diferente{
    background-image: url("../img/fundo2-1.jpg");
    background-size: 40%;
    background-repeat: no-repeat;
    background-position: left;
    padding: 50px 0px;
    margin-top: 30px;
}

.diferente p{
    font-size: 19px;
}

.diferente h1{
    text-align: left;
}

.ganho{
    position: relative;
    padding-bottom: 50px;
    padding-top: 30px;
}

.ganho::before{
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    background-image: url("../img/maps.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
    
}

.ganho .sub-title{
    text-align: center;
    font-size: 19px;
}

.ganho .ant-collapse-header-text{
    font-weight: 600;
    color: black;
}

.ganho .ant-collapse-content-box, .ganho .ant-collapse-content.ant-collapse-content-active{
    background-color: transparent;
}

.ganho .collapse-main{
    margin-top: 50px;
}

.nao-isso{
    text-align: center;
    color:var(--light-blue);;
    font-size: 32px;
    font-weight: 600;
}

.beneficios{
    background-image: url("../img/fundo3.jpg");
    background-repeat: no-repeat;
    background-position: right;
    padding-bottom: 100px;
}

.beneficios .icon img{
    width: 80px;
}

.icon-box{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 230px;
    background-color: white;
    border-radius: 15px;
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.1);
}

.icon-box .titulo{
    color: var(--light-blue);;
    font-size: 22px;
    line-height: 1;
    font-weight: 400;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-left: 5px;
    padding-right: 5px;
}

.icon-box .texto{
    font-size: 16px;
    line-height: 1.3;
    padding-left: 5px;
    padding-right: 5px;
}

.linha1{
    margin-bottom: 50px;
}

.linha2 .icon-box{
    height: 310px;
}

.direitos{
    text-align: right;
}

.rodape{
    background-color: var(--light-blue);
    padding-top: 40px;
    padding-bottom: 100px;
    color: white;
}

.rodape a{
    color: white;
}

.login{
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #cbdeff;
}

.cnpj_input{
    padding: 5px;
    width: 100%;
    border-radius: 10px;
    border-color: #193D96;
    outline: 0;
    border-width: 0px;
}
.cnpj_input:focus { 
    outline: none !important;
    outline: 0;
    border:0px solid #193D96;
}

/* FORM LOGIN */
.text-right{
    text-align: right;
    display: flex;
    /* display: inline-flex; */
    flex-wrap: wrap;
    gap: 12px;
    align-items: center;
    justify-content: center;
}
.login .texto p{
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 14px;
    color: var(--dark-blue);
}

.login .texto p span{
    color: var(--light-blue);;
    font-weight: 600;

}

.login .texto{
    flex: 10;
    flex-shrink: 0;
}
.login .etapas{
    flex: 4;
    flex-shrink: 0;
}
.login .enviar{
    flex: 1;
    flex-shrink: 0;
}

.btn-small{
    text-transform: uppercase;
    padding: 5px 10px;
    background-color: var(--light-blue);;
    transition: .3s;
    font-size: 12px;
    border-radius: 50px;
    border: 0px;
    color: white;
    box-shadow: 0px 1px 22px 0px rvar(--light-blue-hover) ;
}

.btn-small:hover{
    background-color: var(--dark-blue-hover);;
    transition: .3s;
    border: 0px;
    cursor: pointer;
}

.operador{
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.form-control label{
    color: var(--light-blue) !important;
}


.form-control input[aria-invalid="true"]{
    border-color: #ff4d4f;
    background: #ffffff;
}
.form-control input{
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    border-radius: 6px;
    transition: all 0.2s;
    background: #ffffff;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
}
.form-control input:hover{
    border-color: #4096ff !important;
    background-color: #ffffff !important;
    box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
}
.form-control input:focus,
.form-control input:focus-visible{
    border-color: #4096ff !important;
    background-color: #ffffff !important;
    outline: unset;
}

.ant-form-item-explain-error{
    background-color: white;
    position: absolute;
    bottom: 18px ;
    left: 10px;
    font-size: 10px;
    z-index: 10;
    padding: 0 5px;
}

.text-right {
    text-align: right;
    display: flex;
    /* display: inline-flex; */
    flex-wrap: wrap;
    gap: 12px;
    align-items: end;
    justify-content: end;
}

.text-center {
    text-align: center;
    display: flex;
    /* display: inline-flex; */
    flex-wrap: wrap;
    gap: 12px;
    align-items: center;
    justify-content: center;
}

.hidden{
    display: none;
    
}

.ant-divider-inner-text::before,
.ant-divider-inner-text::after {
    border-color: var(--dark-blue) !important;
    border-block-start: 2px solid transparent;
}

.ant-divider-inner-text{
    color: var(--light-blue);
}

.ant-input.ant-input-outlined::placeholder{
    color: lightgray !important;
}

@media (min-width: 577px) {
    .login .ant-form-item.form-control{
        margin-bottom: 0px !important;
    }
}
@media (max-width: 576px) {
    .login  .form-control{
        margin-bottom: 16px !important;
    }
}

@media (max-width: 820px) {
    .topo .title{
        font-size: 36px;
    }
    .topo .destaque{
        font-size: 22px;
    }

    .video-wrapper .white-ghost iframe{
        width: 100%;
        height: 385px;
    }

    h1.title.small{
        font-size: 28px;
    }
    .lista-flex{
        flex-direction: column;
    }
    .icon-box{
        width: 100%;
    }
    .topo .center{
        text-align: center;
    }
    .rodape{
        text-align: center;
    }
    .go-100{
        width: 100%;
    }
    .rodape .direitos{
        text-align: center;
        margin-top: 20px;
    }

    .text-right{
       flex-direction: column;
       text-align: center;
    }
    .operador{
        flex-direction: column;
    }
    .login-new-operador{
        margin-top: 10px;
    }

    .title-login{
        text-align: center !important;
    }
}